<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-start pt-4>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Room Amenities</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm11>
        <div v-for="(facilities, category) in groupedFacilities" :key="category">
      <v-layout wrap justify-start px-4 pt-4>
        <v-flex xs12 text-left >    
            <span class="facility-category dialogText">{{ category }} :</span>
        </v-flex>
        <!-- Iterate over each facility in the current category -->
        <v-flex xs12 sm4 v-for="facility in facilities" :key="facility._id" pl-0 pl-md-4>
          <v-switch
            v-model="facility.isSelected"
            :label="facility.name"
            inset 
            color="#002635"
          ></v-switch>
        </v-flex>
      </v-layout>
    </div>
      </v-flex>
      <v-flex xs12 sm10 pb-4>
        <v-layout wrap justify-end>
          <v-flex xs12 sm6 md3 pt-4 text-right>
            <v-btn  block @click="add()" class="btnstly" style="cursor: pointer"
              ><span style="font-family: LexendFont; text-transform: none"
                >Save</span
              ></v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
 
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: {
    hotelId: {
      type: String,
      required: true
    },
    RoomAmenitiesList: {
      type: Array,
      required: true
    },
    roomId: {
    type: String,  
    required: true 
  }
  },
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
     
    };
  },
  // mounted() {
  //   this.getData();
  // },
  computed: {
    groupedFacilities() {
      // Use reduce to group facilities by facilityCategory
      return this.RoomAmenitiesList.reduce((acc, facility) => {
        if (!acc[facility.facilityCategory]) {
          acc[facility.facilityCategory] = [];
        }
        acc[facility.facilityCategory].push(facility);
        return acc;
      }, {});
    }
  },
  methods: {
    // getData() {
    //   this.appLoading = true;
    //   axios({
    //     url: "/facilities/get/list",
    //     method: "POST",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       page: this.page,
    //       limit: this.limit,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       this.data = response.data.data;
    //       this.Pagelength = Math.ceil(response.data.totalLength / this.limit);
    //     })
    //     .catch((err) => {
    //       //   this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    add() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/room/facilities/edit",
        data: {
          facilities: this.RoomAmenitiesList,
          roomId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.$emit('id-received', { hotelId: this.$route.query.id }); 
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}
</style>
